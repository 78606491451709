.section {
    padding: 3rem 0;

    @include screen-min(m) {
        padding: 6rem 0;
    }

    &__header {
        margin-bottom: 4rem;
        position: relative;
        text-align: center;
        z-index: 10;

        @include screen-min(l) {
            text-align: left;
        }

        &--center {
            @include screen-min(l) {
                text-align: center;
            }
        }
    }

    &__intro {
        color: var(--color-2);
        font-family: var(--font-2);
        font-size: var(--font-size-xl);
        font-style: italic;
        margin-top: .75em;

        @include screen-min(m) {
            font-size: 24px;
        }
    }

    &__glow {
        aspect-ratio: 1/1;
        height: 100vh;
        left: 50%;
        opacity: calc(0.75 + (var(--equinox-abs) * -1));
        pointer-events: none;
        position: absolute;
        transform: translate3d(-50%, -50%, 0);
        top: 50%;
        width: 100vh;
        max-width: none;
        user-select: none;
        z-index: 1;

        @include screen-min(l) {
            height: 100vw;
            width: 100vw;
        }
    }

    &__image,
    &__text {
        position: relative;
        z-index: 10;
    }

    &__body {
        color: var(--color-2);
        font-family: var(--font-2);
        font-size: var(--font-size-l);
        hyphens: auto;
        line-height: var(--line-height-l);

        @include screen-min(m) {
            font-size: var(--font-size-xl);
        }

        a {
            color: #FFF;
            text-decoration: underline;
            text-decoration-color: rgb(255 255 255 / 50%);
            text-decoration-thickness: 1px;
            text-underline-offset: 0.15em;
            transition: 250ms ease;

            &:hover {
                text-decoration-color: rgb(255 255 255 / 100%);
            }
        }

        p {
            margin: 1em 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}