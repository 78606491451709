@keyframes textSplitChar {
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.textSplit {
    --duration: 1s;

    .word {
        --word-index: 0;
        display: inline-block;
        opacity: 0;
        overflow: hidden;
        transform: translate3d(0, 1em, 0);
        vertical-align: bottom;
        white-space: nowrap;

        &::after {
            content: ' ';
            white-space: pre;
        }
    }

    .space {
        vertical-align: bottom;
    }

    .char {
        display: inline-block;
        opacity: 0;
        transform: translate3d(0, 1em, 0);
    }

    &.is-textSplitVisible {
        .word {
            animation: textSplitChar var(--duration) cubic-bezier(0.5, 0, 0.25, 1) both;
            // animation-delay: calc((var(--duration) * 0.5) * (var(--char-index) / var(--num-chars)));
            animation-delay: calc(250ms * (var(--word-index) / var(--num-words)));
        }
    }
}
