.modules {
    background-position: center bottom;

    &__radio {
        height: 0;
        position: absolute;
        visibility: hidden;
        width: 0;
    }

    &__container {
        align-items: stretch;
        display: flex;
        gap: .5rem;
    }

    &__tiles {
        display: grid;
        flex: 1;
        gap: .5rem;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        z-index: 10;
    }

    &__panels {
        align-items: center;
        background: rgb(var(--color-2-rgb) / 5%);
        border-radius: .5rem;
        display: flex;
        flex: 1;
        padding: 3rem;
    }
}

