.u-text {
    &-serif {
        color: var(--color-2);
        font-family: var(--font-2);
        font-size: var(--font-size-xl);
        line-height: var(--line-height-l);
    }

    &-nbsp {
        white-space: nowrap;
    }
}