.footer {
    bottom: 0;
    color: var(--color-2);
    font-size: var(--font-size-xs);
    left: 0;
    line-height: var(--line-height-l);
    height: var(--footer-height);
    padding: 3rem 0;
    position: fixed;
    width: 100%;
    z-index: 1;

    &__split {
        display: flex;

        @include screen-max(m) {
            flex-direction: column;
            text-align: center;
        }

        @include screen-min(m) {
            justify-content: space-between;
        }
    }

    a {
        color: var(--color-2);
        text-decoration: underline;
        text-decoration-color: rgb(var(--color-2-rgb) / 50%);
        text-underline-offset: 2px;
        transition: 250ms ease;

        &:hover {
            text-decoration-color: rgb(var(--color-2-rgb) / 100%);
        }
    }
}