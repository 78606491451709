.aviation {
    .grid {
        @include screen-min(l) {
            flex-direction: row-reverse;
        }
    }

    &__plane {
        margin: auto;
        position: relative;
        z-index: 4;
        transform: translate3d(calc(var(--equinox) * 15%), calc(var(--equinox) * 20%), 0);
        width: 100%;
    }
}