.hero {
    padding-bottom: 2rem;

    &__visual {
        height: 75vh;
        overflow: hidden;
        position: relative;
        z-index: 1;

        @include screen-min(m) {
            height: 100vh;
        }

        img {
            bottom: 0;
            height: 125%;
            left: 0;
            object-fit: cover;
            object-position: 60% center;
            position: absolute;
            transform: translate3d(0, calc(var(--equinox-pos) * 20%), 0);
            width: 100%;
        }

        &::after {
            background-image: linear-gradient(to bottom, transparent 25%, var(--color-1));
            content: '';
            display: block;
            inset: 0;
            position: absolute;
        }
    }

    &__content {
        margin-top: -25vh;
        position: relative;
        z-index: 2;

        @include screen-min(m) {
            margin-top: -33.33333vh;
            max-width: 66.66666%;
        }
    }

    &__title {
        margin-bottom: .75em;
    }

    &__intro {
        color: var(--color-2);
        font-family: var(--font-2);
        font-size: var(--font-size-l);
        line-height: var(--line-height-l);

        @include screen-min(l) {
            font-size: var(--font-size-xl);
        }

        p:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    &__ctas {
        margin-top: 2rem;

        @include screen-min(m) {
            margin-top: 3rem;
        }
    }
}