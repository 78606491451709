.module {
    align-items: center;
    appearance: none;
    background-color: transparent;
    background-image: linear-gradient(140deg, transparent 50%, rgb(var(--color-2-rgb) / 15%) 100%);
    border: none;
    border-radius: .5rem;
    color: var(--text-color);
    box-shadow: inset 0 0 0 2px rgb(var(--color-2-rgb) / 50%);
    display: flex;
    font-size: 1rem;
    justify-content: flex-start;
    cursor: pointer;
    gap: 1rem;
    min-height: 88px;
    padding: 1rem;
    text-align: left;
    transition: 250ms ease;
    user-select: none;

    @include screen-min(m) {
        padding: 3rem 2rem;
    }

    &__icon {
        aspect-ratio: 1/1;
        height: 32px;
        width: 32px;

        @include screen-min(m) {
            height: 40px;
            margin-bottom: .5em;
            width: 40px;
        }
    }

    &__text {
        flex: 1;
    }

    &__title {
        color: currentColor;

        @include screen-min(m) {
            margin-bottom: .25em;
        }
    }

    &__desc {
        color: var(--color-2);
        display: none;
        line-height: var(--line-height-l);

        @include screen-min(m) {
            display: block;
        }
    }

    &:hover {
        box-shadow: inset 0 0 0 2px rgb(var(--color-2-rgb) / 100%);
    }

    &.is-active {
        color: var(--color-1);
        background: #fff;
        box-shadow: inset 0 0 0 2px rgb(var(--color-2-rgb) / 0%), 0 0 24px rgb(255 255 255 / 50%);
    
        .module__desc {
            color: var(--color-1);
        }
    }
}