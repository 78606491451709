@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
  
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}


.navigator {
    aspect-ratio: 1/1;
    max-width: 640px;
    position: relative;
    width: 100%;
    z-index: 100;

    @include screen-min(l) {
        transform: rotateY(calc(20deg * var(--equinox)));
        transform-style: preserve-3d;
    }

    &__interactive {
        animation: shake 1s infinite;
        background: rgb(var(--color-1-rgb) / 75%);
        border-radius: 8px;
        top: -5%;
        font-size: var(--font-size-s);
        position: absolute;
        padding: 1rem;
        left: calc(50% - 72px);
        text-align: center;
        transition: 500ms ease;
        width: 144px;

        @include screen-min(l) {
            top: auto;
            bottom: 10%;
        }

        .has-interacted & {
            opacity: 0;
        }
    }

    &__trigger {
        aspect-ratio: 1/1;
        appearance: none;
        background: transparent;
        box-shadow: inset 0 0 24px 2px rgb(var(--color-2-rgb) / 50%);
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        position: absolute;
        transition: 250ms ease;
        transform: translate3d(-50%, -50%, 0);
        width: 20%;

        &:hover {
            box-shadow: inset 0 0 16px 2px rgb(var(--color-2-rgb) / 75%);
            z-index: 8;

            .navigator__tooltip {
                display: inline-block;
                opacity: 1;
                transform: translate3d(-50%, -16px, 0);
            }
        }

        &.is-active {
            box-shadow: inset 0 0 0 2px #FFF, inset 0 0 12px 4px #FFF, 0 0 16px 2px #FFF;
            z-index: 8;

            .navigator__icon {
                filter: drop-shadow(0 0 8px #FFF);
                stroke: #FFF;
            }
        }

        &.is-green.is-active {
            box-shadow: inset 0 0 0 2px #36ff00, inset 0 0 12px 4px #36ff00, 0 0 16px 2px #36ff00;

            .navigator__icon {
                filter: drop-shadow(0 0 8px #36ff00);
                stroke: #36ff00;
            }
        }

        &.is-red.is-active {
            box-shadow: inset 0 0 0 2px #ff3369, inset 0 0 12px 4px #ff3369, 0 0 16px 2px #ff3369;

            .navigator__icon {
                filter: drop-shadow(0 0 8px #ff3369);
                stroke: #ff3369;
            }
        }
    }

    &__icon {
        aspect-ratio: 1/1;
        fill: none;
        margin: auto;
        stroke: var(--color-2);
        stroke-width: 1.75;
        stroke-linecap: round;
        stroke-linejoin: round;
        transition: 250ms ease;
        width: 50%;

        @include screen-min(m) {
            stroke-width: 2.5;
        }

        & > * {
            vector-effect: non-scaling-stroke;
        }

        #main & {
            stroke-width: 2.75;

            @include screen-min(m) {
                stroke-width: 3.5;
            }
        }
    }

    &__tooltip {
        background: #fff;
        border-radius: 14px;
        color: var(--bg-color);
        display: inline-block;
        font-size: var(--font-size-m);
        font-weight: 600;
        left: 50%;
        line-height: 28px;
        opacity: 0;
        padding: 0 14px;
        pointer-events: none;
        position: absolute;
        top: -24px;
        transition: 250ms ease;
        transform: translate3d(-50%, 0, 0);
        white-space: nowrap;

        &::after {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #fff;
            bottom: -5px;
            content: '';
            left: calc(50% - 5px);
            position: absolute;
            height: 0;
            width: 0; 
        }
    }

    #task-overview {
        left: 50%;
        top: 50%;
        width: 40%;
    }

    #normal-execution {
        left: 75%;
        top: 25%;
    }

    #equipment {
        left: 85%;
        top: 50%;
    }

    #challenging-situations {
        left: 75%;
        top: 75%;
    }

    #common-errors {
        left: 25%;
        top: 75%;
    }

    #adverse-events {
        left: 15%;
        top: 50%;
    }

    #evidence {
        left: 25%;
        top: 25%;
    }
}