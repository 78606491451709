.quote {
    line-height: var(--line-height-l);
    margin-bottom: 3rem;

    &__body {
        color: var(--text-color);
        font-family: var(--font-2);
        font-size: 24px;
        font-style: italic;
        margin-bottom: .5em;
    }

    &__author {
        color: var(--text-color);
        font-family: var(--font-3);
        font-weight: 600;
    }

    &__authorCredentials {
        color: var(--color-2);
        font-size: var(--font-size-s);
        font-family: var(--font-1);
    }
}