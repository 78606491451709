.circle {
    aspect-ratio: 1/1;
    display: flex;
    margin: auto;
    max-width: 85%;
    position: relative;

    &::after,
    &::before {
        content: '';
        display: block;
        inset: 0;
        position: absolute;
    }

    &::before {
        background-image: url('/assets/img/circle.png');
        background-size: contain;
        transform: translate3d(-75%, -20%, 0);
        opacity: .25;
        z-index: 1;

        @include screen-min(l) {
            transform: translate3d(60%, -60%, 0);
        }
    }

    &::after {
        border: 1px solid var(--color-2);
        transform: translate3d(75%, 20%, 0);
        opacity: .5;
        border-radius: 50%;
        z-index: 2;

        @include screen-min(l) {
            transform: translate3d(60%, 60%, 0);
        }
    }

    &__content {
        inset: 0;
        position: absolute;
        border-radius: 50%;
        overflow: hidden;
        z-index: 3;

        img {
            bottom: 0;
            height: 125%;
            left: 0;
            object-fit: cover;
            position: absolute;
            transform: translate3d(0, calc(var(--equinox-pos) * 20%), 0);
            width: 100%;
        }
    }
}