.u-hide {
    display: none !important;
}

.u-hideEmpty:empty {
    display: none !important;
}

@include screen-max(xs) {
    .xs\:u-show {
        display: none !important;
    }
}

@include screen-min(xs) {
    .xs\:u-hide {
        display: none !important;
    }
}

@include screen-max(s) {
    .s\:u-show,
    .m\:u-show,
    .l\:u-show,
    .xl\:u-show {
        display: none !important;
    }
}

@include screen-min(s) {
    .s\:u-hide {
        display: none !important;
    }
}

@include screen-max(m) {
    .m\:u-show,
    .l\:u-show,
    .xl\:u-show {
        display: none !important;
    }
}

@include screen-min(m) {
    .m\:u-hide {
        display: none !important;
    }
}

@include screen-max(l) {
    .l\:u-show,
    .xl\:u-show {
        display: none !important;
    }
}

@include screen-min(l) {
    .l\:u-hide {
        display: none !important;
    }
}

@include screen-max(xl) {
    .xl\:u-show {
        display: none !important;
    }
}

@include screen-min(xl) {
    .xl\:u-hide {
        display: none !important;
    }
}