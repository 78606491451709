.partners {
    &__grid {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4rem;
        margin-top: 3rem;
    }

    &__logo {
        height: auto;
        max-height: 64px;
        max-width: 100%;
        width: 240px;
    }
}