@keyframes interfacePanel {
    from {
        opacity: 0;
        transform: translateY(16px);
    }
}

.interfacePanel {
    display: none;

    &__title {
        margin-bottom: .75em;
    }

    &.is-active {
        animation: interfacePanel 500ms ease both;
        display: block;   
    }
}