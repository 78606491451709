@keyframes testimonial {
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.testimonial {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    opacity: 0;
    text-align: center;
    transform: translate3d(0, 1rem, 0);

    &__photo {
        aspect-ratio: 1/1;
        border-radius: 50%;
        height: 80px;
        margin: 0 auto 1.5rem;
    }

    &__quote {
        font-family: var(--font-2);
        font-size: var(--font-size-l);
        margin-bottom: 1.5rem;
        line-height: var(--line-height-l);

        i {
            font-style: italic;
        }
    }

    .is-visibleOnce & {
        animation: testimonial 2s ease both;

        &--1 {
            animation-delay: 250ms !important;
        }
    
        &--2 {
            animation-delay: 500ms;
        }
    
        &--3 {
            animation-delay: 750ms;
        }
    
        &--4 {
            animation-delay: 1000ms;
        }
    }
}