@keyframes modulePanel {
    from {
        opacity: 0;
        transform: translateY(16px);
    }
}

.modulePanel {
    display: none;

    &__title {
        margin-bottom: .75em;
    }

    &.is-active {
        animation: modulePanel 500ms ease both;
        display: block;
    }
}