h6 {
    font-family: var(--font-3);
    font-size: var(--font-size-xs);
    font-weight: 600;
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase;

    @include screen-min(m) {
        letter-spacing: 0.15em;
    }
}