.architectureTier {
    align-items: center;
    display: flex;
    gap: 2rem;

    &__number {
        color: var(--color-2);
        font-family: var(--font-2);
        font-size: 40px;
        font-style: italic;
    }

    &__title {
        margin-bottom: 0.25em;
    }

    &__desc {
        color: var(--color-2);
        font-size: var(--font-size-s);
        line-height: var(--line-height-l);
    }
}