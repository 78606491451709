:root {
    --gutter: 20px;

    --font-1: 'Inter', sans-serif;
    --font-2: 'Martina Plantijn', sans-serif;
    --font-3: 'Hellix', sans-serif;

    --font-size-xs: .75em;
    --font-size-s: .875em;
    --font-size-m: 1em;
    --font-size-l: 1.125em;
    --font-size-xl: 1.25em;
    --font-size-xxl: 1.75em;
    --font-size-3xl: 2.5em;

    --line-height-s: 1.15;
    --line-height-m: 1.25;
    --line-height-l: 1.5;

    --color-1: #0F131F;
    --color-1-rgb: 15 19 31;
    --color-2: #96B9CC;
    --color-2-rgb: 148 184 204;
    --color-3: #12182A;
    --color-3-rgb: 18 24 43;
    --color-4: #AF89C0;
    --color-4-rgb: 176 139 193;

    --bg-color: var(--color-1);
    --text-color: #ffffff;

    --footer-height: 120px;

    --btn-bg-color: #FFF;
    --btn-bg-color-hover: #E3D3FF;
}

@media (min-width: 768px) {
    :root {
        --gutter: 80px;

        --font-size-xxl: 2em;
        --font-size-3xl: 3.5em;
    }
}

$grid-columns: 12 !default;

$screens: (
    xs: 320px,
    s: 375px,
    m: 768px,
    l: 1024px,
    xl: 1440px,
) !default;

$spaces: (
    xxs:    0.125rem, // 2px
    xs:     0.25rem,  // 4px
    s:      0.5rem,   // 8px
    m:      1rem,     // 16px
    l:      1.5rem,   // 24px
    xl:     2rem,     // 32px
    xxl:    3rem,     // 48px
    3xl:    4rem,     // 64px
    4xl:    5rem,     // 80px
    5xl:    6rem,     // 96px
    6xl:    7rem,     // 112px
) !default;