/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --gutter: 20px;
  --font-1: "Inter", sans-serif;
  --font-2: "Martina Plantijn", sans-serif;
  --font-3: "Hellix", sans-serif;
  --font-size-xs: .75em;
  --font-size-s: .875em;
  --font-size-m: 1em;
  --font-size-l: 1.125em;
  --font-size-xl: 1.25em;
  --font-size-xxl: 1.75em;
  --font-size-3xl: 2.5em;
  --line-height-s: 1.15;
  --line-height-m: 1.25;
  --line-height-l: 1.5;
  --color-1: #0F131F;
  --color-1-rgb: 15 19 31;
  --color-2: #96B9CC;
  --color-2-rgb: 148 184 204;
  --color-3: #12182A;
  --color-3-rgb: 18 24 43;
  --color-4: #AF89C0;
  --color-4-rgb: 176 139 193;
  --bg-color: var(--color-1);
  --text-color: #ffffff;
  --footer-height: 120px;
  --btn-bg-color: #FFF;
  --btn-bg-color-hover: #E3D3FF;
}

@media (min-width: 768px) {
  :root {
    --gutter: 80px;
    --font-size-xxl: 2em;
    --font-size-3xl: 3.5em;
  }
}
/* prettier-ignore */
@font-face {
  font-display: swap;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Hellix-Regular.woff2") format("woff2"), url("/assets/fonts/Hellix-Regular.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/Hellix-SemiBold.woff2") format("woff2"), url("/assets/fonts/Hellix-SemiBold.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Inter-Regular.woff2") format("woff2"), url("/assets/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/Inter-Medium.woff2") format("woff2"), url("/assets/fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Martina Plantijn";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Martina-Plantijn-Regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Martina Plantijn";
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/Martina-Plantijn-Italic.woff2") format("woff2");
}
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

body {
  background: #000;
  color: var(--text-color);
  font-family: var(--font-1);
  line-height: var(--line-height-m);
  margin-bottom: var(--footer-height);
  overflow-x: hidden;
  position: relative;
}

h1 {
  font-family: var(--font-3);
  font-size: var(--font-size-3xl);
  font-weight: 600;
  line-height: var(--line-height-s);
}
h1 i {
  font-weight: 400;
}

h2 {
  font-family: var(--font-3);
  font-size: var(--font-size-3xl);
  font-weight: 600;
  line-height: var(--line-height-s);
}

h3 {
  color: #fff;
  font-family: var(--font-3);
  font-size: var(--font-size-xxl);
  font-weight: 600;
}

h4 {
  font-family: var(--font-3);
  font-size: var(--font-size-l);
  font-weight: 600;
  line-height: var(--line-height-s);
}
@media (min-width: 768px) {
  h4 {
    font-size: var(--font-size-xl);
  }
}

h5 {
  font-family: var(--font-3);
  font-size: var(--font-size-m);
  font-weight: 600;
  line-height: var(--line-height-m);
}

h6 {
  font-family: var(--font-3);
  font-size: var(--font-size-xs);
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  h6 {
    letter-spacing: 0.15em;
  }
}

html {
  font-size: 16px;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

@media (min-width: 1024px) {
  .architecture .grid {
    flex-direction: row-reverse;
  }
}
.architecture__arrow {
  position: absolute;
  height: 100%;
  top: 0;
  width: auto;
  left: 0;
}
.architecture__tiers {
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
}
@media (min-width: 1024px) {
  .architecture__tiers {
    flex-direction: column;
  }
}
.architecture__stack {
  margin: auto;
  max-height: 40vh;
  position: relative;
}
@media (min-width: 1024px) {
  .architecture__stack {
    max-height: min(65vh, 600px);
  }
}

@media (min-width: 1024px) {
  .aviation .grid {
    flex-direction: row-reverse;
  }
}
.aviation__plane {
  margin: auto;
  position: relative;
  z-index: 4;
  transform: translate3d(calc(var(--equinox) * 15%), calc(var(--equinox) * 20%), 0);
  width: 100%;
}

.interface__3d {
  perspective: 100vh;
}

.modules {
  background-position: center bottom;
}
.modules__radio {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}
.modules__container {
  align-items: stretch;
  display: flex;
  gap: 0.5rem;
}
.modules__tiles {
  display: grid;
  flex: 1;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  z-index: 10;
}
.modules__panels {
  align-items: center;
  background: rgb(var(--color-2-rgb)/5%);
  border-radius: 0.5rem;
  display: flex;
  flex: 1;
  padding: 3rem;
}

.partners__grid {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
  margin-top: 3rem;
}
.partners__logo {
  height: auto;
  max-height: 64px;
  max-width: 100%;
  width: 240px;
}

.team__block {
  background-color: var(--color-3);
  border-radius: 8px;
  padding: 1.5rem 0;
}
@media (min-width: 768px) {
  .team__block {
    padding: 3rem 1rem;
  }
}
.team__block:not(:last-child) {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .team__block:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.team__block h6 {
  margin-bottom: 2.5rem;
}
.team__block--lighter {
  background-color: rgb(var(--color-3-rgb)/50%);
}

.architectureTier {
  align-items: center;
  display: flex;
  gap: 2rem;
}
.architectureTier__number {
  color: var(--color-2);
  font-family: var(--font-2);
  font-size: 40px;
  font-style: italic;
}
.architectureTier__title {
  margin-bottom: 0.25em;
}
.architectureTier__desc {
  color: var(--color-2);
  font-size: var(--font-size-s);
  line-height: var(--line-height-l);
}

.btn {
  align-items: center;
  backdrop-filter: blur(4px);
  background: transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 12px 2px rgb(var(--color-2-rgb)/50%);
  color: #FFF;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  gap: 0.5em;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  text-decoration: none;
  transition: box-shadow 250ms ease;
}
@media (min-width: 768px) {
  .btn {
    border-radius: 26px;
    gap: 0.75em;
    height: 52px;
    line-height: 52px;
    padding: 0 26px;
  }
}
.btn span {
  display: inline-block;
  position: relative;
}
.btn__icon {
  height: 16px;
  position: relative;
  width: 16px;
}
.btn:active span,
.btn:active .btn__icon {
  top: 1px;
}
.btn:hover {
  box-shadow: inset 0 0 8px 2px rgb(var(--color-2-rgb)/75%);
}

.circle {
  aspect-ratio: 1/1;
  display: flex;
  margin: auto;
  max-width: 85%;
  position: relative;
}
.circle::after, .circle::before {
  content: "";
  display: block;
  inset: 0;
  position: absolute;
}
.circle::before {
  background-image: url("/assets/img/circle.png");
  background-size: contain;
  transform: translate3d(-75%, -20%, 0);
  opacity: 0.25;
  z-index: 1;
}
@media (min-width: 1024px) {
  .circle::before {
    transform: translate3d(60%, -60%, 0);
  }
}
.circle::after {
  border: 1px solid var(--color-2);
  transform: translate3d(75%, 20%, 0);
  opacity: 0.5;
  border-radius: 50%;
  z-index: 2;
}
@media (min-width: 1024px) {
  .circle::after {
    transform: translate3d(60%, 60%, 0);
  }
}
.circle__content {
  inset: 0;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  z-index: 3;
}
.circle__content img {
  bottom: 0;
  height: 125%;
  left: 0;
  object-fit: cover;
  position: absolute;
  transform: translate3d(0, calc(var(--equinox-pos) * 20%), 0);
  width: 100%;
}

.footer {
  bottom: 0;
  color: var(--color-2);
  font-size: var(--font-size-xs);
  left: 0;
  line-height: var(--line-height-l);
  height: var(--footer-height);
  padding: 3rem 0;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.footer__split {
  display: flex;
}
@media (max-width: 767px) {
  .footer__split {
    flex-direction: column;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__split {
    justify-content: space-between;
  }
}
.footer a {
  color: var(--color-2);
  text-decoration: underline;
  text-decoration-color: rgb(var(--color-2-rgb)/50%);
  text-underline-offset: 2px;
  transition: 250ms ease;
}
.footer a:hover {
  text-decoration-color: rgb(var(--color-2-rgb)/100%);
}

.grid {
  display: flex;
  flex-flow: row wrap;
}
.grid__col {
  background-clip: padding-box;
  border: 0px solid transparent;
  flex: 0 0 100%;
  min-width: 0;
  position: relative;
}
.grid__col--end {
  align-self: flex-end;
}
.grid__col--middle {
  align-self: flex-middle;
}
.grid__col--start {
  align-self: flex-start;
}
.grid--between {
  justify-content: space-between;
}
.grid--center {
  justify-content: center;
}
.grid--end {
  align-items: flex-end;
}
.grid--evenly {
  justify-content: space-evenly;
}
.grid--middle {
  align-items: center;
}
@media (min-width: 1024px) {
  .grid--reverse {
    flex-direction: row-reverse;
  }
}
@media (max-width: 1023px) {
  .grid--scrolling {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--gutter);
    margin-left: calc(var(--gutter) * -1);
    margin-right: calc(var(--gutter) * -1);
    overflow: auto;
    padding-left: var(--gutter);
    padding-right: var(--gutter);
    scroll-padding: 0 var(--gutter);
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
  }
  .grid--scrolling::-webkit-scrollbar {
    display: none;
  }
  .grid--scrolling .grid__col {
    border: none !important;
    scroll-snap-align: start;
    flex: 0 0 75vw;
  }
}
.grid--start {
  align-items: flex-start;
}
.grid--right {
  justify-content: flex-end;
}

.grid__col--span-1 {
  flex-basis: 8.3333333333%;
}

.grid__col--span-2 {
  flex-basis: 16.6666666667%;
}

.grid__col--span-3 {
  flex-basis: 25%;
}

.grid__col--span-4 {
  flex-basis: 33.3333333333%;
}

.grid__col--span-5 {
  flex-basis: 41.6666666667%;
}

.grid__col--span-6 {
  flex-basis: 50%;
}

.grid__col--span-7 {
  flex-basis: 58.3333333333%;
}

.grid__col--span-8 {
  flex-basis: 66.6666666667%;
}

.grid__col--span-9 {
  flex-basis: 75%;
}

.grid__col--span-10 {
  flex-basis: 83.3333333333%;
}

.grid__col--span-11 {
  flex-basis: 91.6666666667%;
}

.grid__col--span-12 {
  flex-basis: 100%;
}

.grid--gap-xxs {
  margin: -0.0625rem;
}
.grid--gap-xxs > .grid__col {
  border-width: 0.0625rem;
}

.grid--gap-xs {
  margin: -0.125rem;
}
.grid--gap-xs > .grid__col {
  border-width: 0.125rem;
}

.grid--gap-s {
  margin: -0.25rem;
}
.grid--gap-s > .grid__col {
  border-width: 0.25rem;
}

.grid--gap-m {
  margin: -0.5rem;
}
.grid--gap-m > .grid__col {
  border-width: 0.5rem;
}

.grid--gap-l {
  margin: -0.75rem;
}
.grid--gap-l > .grid__col {
  border-width: 0.75rem;
}

.grid--gap-xl {
  margin: -1rem;
}
.grid--gap-xl > .grid__col {
  border-width: 1rem;
}

.grid--gap-xxl {
  margin: -1.5rem;
}
.grid--gap-xxl > .grid__col {
  border-width: 1.5rem;
}

.grid--gap-3xl {
  margin: -2rem;
}
.grid--gap-3xl > .grid__col {
  border-width: 2rem;
}

.grid--gap-4xl {
  margin: -2.5rem;
}
.grid--gap-4xl > .grid__col {
  border-width: 2.5rem;
}

.grid--gap-5xl {
  margin: -3rem;
}
.grid--gap-5xl > .grid__col {
  border-width: 3rem;
}

.grid--gap-6xl {
  margin: -3.5rem;
}
.grid--gap-6xl > .grid__col {
  border-width: 3.5rem;
}

@media (min-width: 320px) {
  .xs\:grid__col--span-1 {
    flex-basis: 8.3333333333%;
  }
  .xs\:grid__col--span-2 {
    flex-basis: 16.6666666667%;
  }
  .xs\:grid__col--span-3 {
    flex-basis: 25%;
  }
  .xs\:grid__col--span-4 {
    flex-basis: 33.3333333333%;
  }
  .xs\:grid__col--span-5 {
    flex-basis: 41.6666666667%;
  }
  .xs\:grid__col--span-6 {
    flex-basis: 50%;
  }
  .xs\:grid__col--span-7 {
    flex-basis: 58.3333333333%;
  }
  .xs\:grid__col--span-8 {
    flex-basis: 66.6666666667%;
  }
  .xs\:grid__col--span-9 {
    flex-basis: 75%;
  }
  .xs\:grid__col--span-10 {
    flex-basis: 83.3333333333%;
  }
  .xs\:grid__col--span-11 {
    flex-basis: 91.6666666667%;
  }
  .xs\:grid__col--span-12 {
    flex-basis: 100%;
  }
  .xs\:grid--gap-xxs {
    margin: -0.0625rem;
  }
  .xs\:grid--gap-xxs > .grid__col {
    border-width: 0.0625rem;
  }
  .xs\:grid--gap-xs {
    margin: -0.125rem;
  }
  .xs\:grid--gap-xs > .grid__col {
    border-width: 0.125rem;
  }
  .xs\:grid--gap-s {
    margin: -0.25rem;
  }
  .xs\:grid--gap-s > .grid__col {
    border-width: 0.25rem;
  }
  .xs\:grid--gap-m {
    margin: -0.5rem;
  }
  .xs\:grid--gap-m > .grid__col {
    border-width: 0.5rem;
  }
  .xs\:grid--gap-l {
    margin: -0.75rem;
  }
  .xs\:grid--gap-l > .grid__col {
    border-width: 0.75rem;
  }
  .xs\:grid--gap-xl {
    margin: -1rem;
  }
  .xs\:grid--gap-xl > .grid__col {
    border-width: 1rem;
  }
  .xs\:grid--gap-xxl {
    margin: -1.5rem;
  }
  .xs\:grid--gap-xxl > .grid__col {
    border-width: 1.5rem;
  }
  .xs\:grid--gap-3xl {
    margin: -2rem;
  }
  .xs\:grid--gap-3xl > .grid__col {
    border-width: 2rem;
  }
  .xs\:grid--gap-4xl {
    margin: -2.5rem;
  }
  .xs\:grid--gap-4xl > .grid__col {
    border-width: 2.5rem;
  }
  .xs\:grid--gap-5xl {
    margin: -3rem;
  }
  .xs\:grid--gap-5xl > .grid__col {
    border-width: 3rem;
  }
  .xs\:grid--gap-6xl {
    margin: -3.5rem;
  }
  .xs\:grid--gap-6xl > .grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 375px) {
  .s\:grid__col--span-1 {
    flex-basis: 8.3333333333%;
  }
  .s\:grid__col--span-2 {
    flex-basis: 16.6666666667%;
  }
  .s\:grid__col--span-3 {
    flex-basis: 25%;
  }
  .s\:grid__col--span-4 {
    flex-basis: 33.3333333333%;
  }
  .s\:grid__col--span-5 {
    flex-basis: 41.6666666667%;
  }
  .s\:grid__col--span-6 {
    flex-basis: 50%;
  }
  .s\:grid__col--span-7 {
    flex-basis: 58.3333333333%;
  }
  .s\:grid__col--span-8 {
    flex-basis: 66.6666666667%;
  }
  .s\:grid__col--span-9 {
    flex-basis: 75%;
  }
  .s\:grid__col--span-10 {
    flex-basis: 83.3333333333%;
  }
  .s\:grid__col--span-11 {
    flex-basis: 91.6666666667%;
  }
  .s\:grid__col--span-12 {
    flex-basis: 100%;
  }
  .s\:grid--gap-xxs {
    margin: -0.0625rem;
  }
  .s\:grid--gap-xxs > .grid__col {
    border-width: 0.0625rem;
  }
  .s\:grid--gap-xs {
    margin: -0.125rem;
  }
  .s\:grid--gap-xs > .grid__col {
    border-width: 0.125rem;
  }
  .s\:grid--gap-s {
    margin: -0.25rem;
  }
  .s\:grid--gap-s > .grid__col {
    border-width: 0.25rem;
  }
  .s\:grid--gap-m {
    margin: -0.5rem;
  }
  .s\:grid--gap-m > .grid__col {
    border-width: 0.5rem;
  }
  .s\:grid--gap-l {
    margin: -0.75rem;
  }
  .s\:grid--gap-l > .grid__col {
    border-width: 0.75rem;
  }
  .s\:grid--gap-xl {
    margin: -1rem;
  }
  .s\:grid--gap-xl > .grid__col {
    border-width: 1rem;
  }
  .s\:grid--gap-xxl {
    margin: -1.5rem;
  }
  .s\:grid--gap-xxl > .grid__col {
    border-width: 1.5rem;
  }
  .s\:grid--gap-3xl {
    margin: -2rem;
  }
  .s\:grid--gap-3xl > .grid__col {
    border-width: 2rem;
  }
  .s\:grid--gap-4xl {
    margin: -2.5rem;
  }
  .s\:grid--gap-4xl > .grid__col {
    border-width: 2.5rem;
  }
  .s\:grid--gap-5xl {
    margin: -3rem;
  }
  .s\:grid--gap-5xl > .grid__col {
    border-width: 3rem;
  }
  .s\:grid--gap-6xl {
    margin: -3.5rem;
  }
  .s\:grid--gap-6xl > .grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 768px) {
  .m\:grid__col--span-1 {
    flex-basis: 8.3333333333%;
  }
  .m\:grid__col--span-2 {
    flex-basis: 16.6666666667%;
  }
  .m\:grid__col--span-3 {
    flex-basis: 25%;
  }
  .m\:grid__col--span-4 {
    flex-basis: 33.3333333333%;
  }
  .m\:grid__col--span-5 {
    flex-basis: 41.6666666667%;
  }
  .m\:grid__col--span-6 {
    flex-basis: 50%;
  }
  .m\:grid__col--span-7 {
    flex-basis: 58.3333333333%;
  }
  .m\:grid__col--span-8 {
    flex-basis: 66.6666666667%;
  }
  .m\:grid__col--span-9 {
    flex-basis: 75%;
  }
  .m\:grid__col--span-10 {
    flex-basis: 83.3333333333%;
  }
  .m\:grid__col--span-11 {
    flex-basis: 91.6666666667%;
  }
  .m\:grid__col--span-12 {
    flex-basis: 100%;
  }
  .m\:grid--gap-xxs {
    margin: -0.0625rem;
  }
  .m\:grid--gap-xxs > .grid__col {
    border-width: 0.0625rem;
  }
  .m\:grid--gap-xs {
    margin: -0.125rem;
  }
  .m\:grid--gap-xs > .grid__col {
    border-width: 0.125rem;
  }
  .m\:grid--gap-s {
    margin: -0.25rem;
  }
  .m\:grid--gap-s > .grid__col {
    border-width: 0.25rem;
  }
  .m\:grid--gap-m {
    margin: -0.5rem;
  }
  .m\:grid--gap-m > .grid__col {
    border-width: 0.5rem;
  }
  .m\:grid--gap-l {
    margin: -0.75rem;
  }
  .m\:grid--gap-l > .grid__col {
    border-width: 0.75rem;
  }
  .m\:grid--gap-xl {
    margin: -1rem;
  }
  .m\:grid--gap-xl > .grid__col {
    border-width: 1rem;
  }
  .m\:grid--gap-xxl {
    margin: -1.5rem;
  }
  .m\:grid--gap-xxl > .grid__col {
    border-width: 1.5rem;
  }
  .m\:grid--gap-3xl {
    margin: -2rem;
  }
  .m\:grid--gap-3xl > .grid__col {
    border-width: 2rem;
  }
  .m\:grid--gap-4xl {
    margin: -2.5rem;
  }
  .m\:grid--gap-4xl > .grid__col {
    border-width: 2.5rem;
  }
  .m\:grid--gap-5xl {
    margin: -3rem;
  }
  .m\:grid--gap-5xl > .grid__col {
    border-width: 3rem;
  }
  .m\:grid--gap-6xl {
    margin: -3.5rem;
  }
  .m\:grid--gap-6xl > .grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 1024px) {
  .l\:grid__col--span-1 {
    flex-basis: 8.3333333333%;
  }
  .l\:grid__col--span-2 {
    flex-basis: 16.6666666667%;
  }
  .l\:grid__col--span-3 {
    flex-basis: 25%;
  }
  .l\:grid__col--span-4 {
    flex-basis: 33.3333333333%;
  }
  .l\:grid__col--span-5 {
    flex-basis: 41.6666666667%;
  }
  .l\:grid__col--span-6 {
    flex-basis: 50%;
  }
  .l\:grid__col--span-7 {
    flex-basis: 58.3333333333%;
  }
  .l\:grid__col--span-8 {
    flex-basis: 66.6666666667%;
  }
  .l\:grid__col--span-9 {
    flex-basis: 75%;
  }
  .l\:grid__col--span-10 {
    flex-basis: 83.3333333333%;
  }
  .l\:grid__col--span-11 {
    flex-basis: 91.6666666667%;
  }
  .l\:grid__col--span-12 {
    flex-basis: 100%;
  }
  .l\:grid--gap-xxs {
    margin: -0.0625rem;
  }
  .l\:grid--gap-xxs > .grid__col {
    border-width: 0.0625rem;
  }
  .l\:grid--gap-xs {
    margin: -0.125rem;
  }
  .l\:grid--gap-xs > .grid__col {
    border-width: 0.125rem;
  }
  .l\:grid--gap-s {
    margin: -0.25rem;
  }
  .l\:grid--gap-s > .grid__col {
    border-width: 0.25rem;
  }
  .l\:grid--gap-m {
    margin: -0.5rem;
  }
  .l\:grid--gap-m > .grid__col {
    border-width: 0.5rem;
  }
  .l\:grid--gap-l {
    margin: -0.75rem;
  }
  .l\:grid--gap-l > .grid__col {
    border-width: 0.75rem;
  }
  .l\:grid--gap-xl {
    margin: -1rem;
  }
  .l\:grid--gap-xl > .grid__col {
    border-width: 1rem;
  }
  .l\:grid--gap-xxl {
    margin: -1.5rem;
  }
  .l\:grid--gap-xxl > .grid__col {
    border-width: 1.5rem;
  }
  .l\:grid--gap-3xl {
    margin: -2rem;
  }
  .l\:grid--gap-3xl > .grid__col {
    border-width: 2rem;
  }
  .l\:grid--gap-4xl {
    margin: -2.5rem;
  }
  .l\:grid--gap-4xl > .grid__col {
    border-width: 2.5rem;
  }
  .l\:grid--gap-5xl {
    margin: -3rem;
  }
  .l\:grid--gap-5xl > .grid__col {
    border-width: 3rem;
  }
  .l\:grid--gap-6xl {
    margin: -3.5rem;
  }
  .l\:grid--gap-6xl > .grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 1440px) {
  .xl\:grid__col--span-1 {
    flex-basis: 8.3333333333%;
  }
  .xl\:grid__col--span-2 {
    flex-basis: 16.6666666667%;
  }
  .xl\:grid__col--span-3 {
    flex-basis: 25%;
  }
  .xl\:grid__col--span-4 {
    flex-basis: 33.3333333333%;
  }
  .xl\:grid__col--span-5 {
    flex-basis: 41.6666666667%;
  }
  .xl\:grid__col--span-6 {
    flex-basis: 50%;
  }
  .xl\:grid__col--span-7 {
    flex-basis: 58.3333333333%;
  }
  .xl\:grid__col--span-8 {
    flex-basis: 66.6666666667%;
  }
  .xl\:grid__col--span-9 {
    flex-basis: 75%;
  }
  .xl\:grid__col--span-10 {
    flex-basis: 83.3333333333%;
  }
  .xl\:grid__col--span-11 {
    flex-basis: 91.6666666667%;
  }
  .xl\:grid__col--span-12 {
    flex-basis: 100%;
  }
  .xl\:grid--gap-xxs {
    margin: -0.0625rem;
  }
  .xl\:grid--gap-xxs > .grid__col {
    border-width: 0.0625rem;
  }
  .xl\:grid--gap-xs {
    margin: -0.125rem;
  }
  .xl\:grid--gap-xs > .grid__col {
    border-width: 0.125rem;
  }
  .xl\:grid--gap-s {
    margin: -0.25rem;
  }
  .xl\:grid--gap-s > .grid__col {
    border-width: 0.25rem;
  }
  .xl\:grid--gap-m {
    margin: -0.5rem;
  }
  .xl\:grid--gap-m > .grid__col {
    border-width: 0.5rem;
  }
  .xl\:grid--gap-l {
    margin: -0.75rem;
  }
  .xl\:grid--gap-l > .grid__col {
    border-width: 0.75rem;
  }
  .xl\:grid--gap-xl {
    margin: -1rem;
  }
  .xl\:grid--gap-xl > .grid__col {
    border-width: 1rem;
  }
  .xl\:grid--gap-xxl {
    margin: -1.5rem;
  }
  .xl\:grid--gap-xxl > .grid__col {
    border-width: 1.5rem;
  }
  .xl\:grid--gap-3xl {
    margin: -2rem;
  }
  .xl\:grid--gap-3xl > .grid__col {
    border-width: 2rem;
  }
  .xl\:grid--gap-4xl {
    margin: -2.5rem;
  }
  .xl\:grid--gap-4xl > .grid__col {
    border-width: 2.5rem;
  }
  .xl\:grid--gap-5xl {
    margin: -3rem;
  }
  .xl\:grid--gap-5xl > .grid__col {
    border-width: 3rem;
  }
  .xl\:grid--gap-6xl {
    margin: -3.5rem;
  }
  .xl\:grid--gap-6xl > .grid__col {
    border-width: 3.5rem;
  }
}
.hero {
  padding-bottom: 2rem;
}
.hero__visual {
  height: 75vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .hero__visual {
    height: 100vh;
  }
}
.hero__visual img {
  bottom: 0;
  height: 125%;
  left: 0;
  object-fit: cover;
  object-position: 60% center;
  position: absolute;
  transform: translate3d(0, calc(var(--equinox-pos) * 20%), 0);
  width: 100%;
}
.hero__visual::after {
  background-image: linear-gradient(to bottom, transparent 25%, var(--color-1));
  content: "";
  display: block;
  inset: 0;
  position: absolute;
}
.hero__content {
  margin-top: -25vh;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .hero__content {
    margin-top: -33.33333vh;
    max-width: 66.66666%;
  }
}
.hero__title {
  margin-bottom: 0.75em;
}
.hero__intro {
  color: var(--color-2);
  font-family: var(--font-2);
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
}
@media (min-width: 1024px) {
  .hero__intro {
    font-size: var(--font-size-xl);
  }
}
.hero__intro p:not(:last-child) {
  margin-bottom: 1em;
}
.hero__ctas {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .hero__ctas {
    margin-top: 3rem;
  }
}

@keyframes interfacePanel {
  from {
    opacity: 0;
    transform: translateY(16px);
  }
}
.interfacePanel {
  display: none;
}
.interfacePanel__title {
  margin-bottom: 0.75em;
}
.interfacePanel.is-active {
  animation: interfacePanel 500ms ease both;
  display: block;
}

.mediaText {
  align-items: center;
  display: flex;
}
.mediaText__media, .mediaText__text {
  display: flex;
  flex: 1;
}

.module {
  align-items: center;
  appearance: none;
  background-color: transparent;
  background-image: linear-gradient(140deg, transparent 50%, rgb(var(--color-2-rgb)/15%) 100%);
  border: none;
  border-radius: 0.5rem;
  color: var(--text-color);
  box-shadow: inset 0 0 0 2px rgb(var(--color-2-rgb)/50%);
  display: flex;
  font-size: 1rem;
  justify-content: flex-start;
  cursor: pointer;
  gap: 1rem;
  min-height: 88px;
  padding: 1rem;
  text-align: left;
  transition: 250ms ease;
  user-select: none;
}
@media (min-width: 768px) {
  .module {
    padding: 3rem 2rem;
  }
}
.module__icon {
  aspect-ratio: 1/1;
  height: 32px;
  width: 32px;
}
@media (min-width: 768px) {
  .module__icon {
    height: 40px;
    margin-bottom: 0.5em;
    width: 40px;
  }
}
.module__text {
  flex: 1;
}
.module__title {
  color: currentColor;
}
@media (min-width: 768px) {
  .module__title {
    margin-bottom: 0.25em;
  }
}
.module__desc {
  color: var(--color-2);
  display: none;
  line-height: var(--line-height-l);
}
@media (min-width: 768px) {
  .module__desc {
    display: block;
  }
}
.module:hover {
  box-shadow: inset 0 0 0 2px rgb(var(--color-2-rgb)/100%);
}
.module.is-active {
  color: var(--color-1);
  background: #fff;
  box-shadow: inset 0 0 0 2px rgb(var(--color-2-rgb)/0%), 0 0 24px rgba(255, 255, 255, 0.5);
}
.module.is-active .module__desc {
  color: var(--color-1);
}

@keyframes modulePanel {
  from {
    opacity: 0;
    transform: translateY(16px);
  }
}
.modulePanel {
  display: none;
}
.modulePanel__title {
  margin-bottom: 0.75em;
}
.modulePanel.is-active {
  animation: modulePanel 500ms ease both;
  display: block;
}

.nav {
  background-image: linear-gradient(to bottom, rgb(var(--color-1-rgb)/100%), rgb(var(--color-1-rgb)/0%) 100%);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.nav .wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem var(--gutter);
}
@media (min-width: 768px) {
  .nav .wrapper {
    padding: 1.5rem var(--gutter);
  }
}
.nav__logo {
  height: 36px;
  width: auto;
}
@media (min-width: 768px) {
  .nav__logo {
    height: 56px;
  }
}
.nav__ctas {
  display: flex;
  gap: 0.5rem;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.navigator {
  aspect-ratio: 1/1;
  max-width: 640px;
  position: relative;
  width: 100%;
  z-index: 100;
}
@media (min-width: 1024px) {
  .navigator {
    transform: rotateY(calc(20deg * var(--equinox)));
    transform-style: preserve-3d;
  }
}
.navigator__interactive {
  animation: shake 1s infinite;
  background: rgb(var(--color-1-rgb)/75%);
  border-radius: 8px;
  top: -5%;
  font-size: var(--font-size-s);
  position: absolute;
  padding: 1rem;
  left: calc(50% - 72px);
  text-align: center;
  transition: 500ms ease;
  width: 144px;
}
@media (min-width: 1024px) {
  .navigator__interactive {
    top: auto;
    bottom: 10%;
  }
}
.has-interacted .navigator__interactive {
  opacity: 0;
}
.navigator__trigger {
  aspect-ratio: 1/1;
  appearance: none;
  background: transparent;
  box-shadow: inset 0 0 24px 2px rgb(var(--color-2-rgb)/50%);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  position: absolute;
  transition: 250ms ease;
  transform: translate3d(-50%, -50%, 0);
  width: 20%;
}
.navigator__trigger:hover {
  box-shadow: inset 0 0 16px 2px rgb(var(--color-2-rgb)/75%);
  z-index: 8;
}
.navigator__trigger:hover .navigator__tooltip {
  display: inline-block;
  opacity: 1;
  transform: translate3d(-50%, -16px, 0);
}
.navigator__trigger.is-active {
  box-shadow: inset 0 0 0 2px #FFF, inset 0 0 12px 4px #FFF, 0 0 16px 2px #FFF;
  z-index: 8;
}
.navigator__trigger.is-active .navigator__icon {
  filter: drop-shadow(0 0 8px #FFF);
  stroke: #FFF;
}
.navigator__trigger.is-green.is-active {
  box-shadow: inset 0 0 0 2px #36ff00, inset 0 0 12px 4px #36ff00, 0 0 16px 2px #36ff00;
}
.navigator__trigger.is-green.is-active .navigator__icon {
  filter: drop-shadow(0 0 8px #36ff00);
  stroke: #36ff00;
}
.navigator__trigger.is-red.is-active {
  box-shadow: inset 0 0 0 2px #ff3369, inset 0 0 12px 4px #ff3369, 0 0 16px 2px #ff3369;
}
.navigator__trigger.is-red.is-active .navigator__icon {
  filter: drop-shadow(0 0 8px #ff3369);
  stroke: #ff3369;
}
.navigator__icon {
  aspect-ratio: 1/1;
  fill: none;
  margin: auto;
  stroke: var(--color-2);
  stroke-width: 1.75;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: 250ms ease;
  width: 50%;
}
@media (min-width: 768px) {
  .navigator__icon {
    stroke-width: 2.5;
  }
}
.navigator__icon > * {
  vector-effect: non-scaling-stroke;
}
#main .navigator__icon {
  stroke-width: 2.75;
}
@media (min-width: 768px) {
  #main .navigator__icon {
    stroke-width: 3.5;
  }
}
.navigator__tooltip {
  background: #fff;
  border-radius: 14px;
  color: var(--bg-color);
  display: inline-block;
  font-size: var(--font-size-m);
  font-weight: 600;
  left: 50%;
  line-height: 28px;
  opacity: 0;
  padding: 0 14px;
  pointer-events: none;
  position: absolute;
  top: -24px;
  transition: 250ms ease;
  transform: translate3d(-50%, 0, 0);
  white-space: nowrap;
}
.navigator__tooltip::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  bottom: -5px;
  content: "";
  left: calc(50% - 5px);
  position: absolute;
  height: 0;
  width: 0;
}
.navigator #task-overview {
  left: 50%;
  top: 50%;
  width: 40%;
}
.navigator #normal-execution {
  left: 75%;
  top: 25%;
}
.navigator #equipment {
  left: 85%;
  top: 50%;
}
.navigator #challenging-situations {
  left: 75%;
  top: 75%;
}
.navigator #common-errors {
  left: 25%;
  top: 75%;
}
.navigator #adverse-events {
  left: 15%;
  top: 50%;
}
.navigator #evidence {
  left: 25%;
  top: 25%;
}

.page {
  background: var(--bg-color);
  box-shadow: 0 0 64px 8px #000;
  max-width: 100%;
  padding-bottom: 6rem;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.quote {
  line-height: var(--line-height-l);
  margin-bottom: 3rem;
}
.quote__body {
  color: var(--text-color);
  font-family: var(--font-2);
  font-size: 24px;
  font-style: italic;
  margin-bottom: 0.5em;
}
.quote__author {
  color: var(--text-color);
  font-family: var(--font-3);
  font-weight: 600;
}
.quote__authorCredentials {
  color: var(--color-2);
  font-size: var(--font-size-s);
  font-family: var(--font-1);
}

.section {
  padding: 3rem 0;
}
@media (min-width: 768px) {
  .section {
    padding: 6rem 0;
  }
}
.section__header {
  margin-bottom: 4rem;
  position: relative;
  text-align: center;
  z-index: 10;
}
@media (min-width: 1024px) {
  .section__header {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .section__header--center {
    text-align: center;
  }
}
.section__intro {
  color: var(--color-2);
  font-family: var(--font-2);
  font-size: var(--font-size-xl);
  font-style: italic;
  margin-top: 0.75em;
}
@media (min-width: 768px) {
  .section__intro {
    font-size: 24px;
  }
}
.section__glow {
  aspect-ratio: 1/1;
  height: 100vh;
  left: 50%;
  opacity: calc(0.75 + var(--equinox-abs) * -1);
  pointer-events: none;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  width: 100vh;
  max-width: none;
  user-select: none;
  z-index: 1;
}
@media (min-width: 1024px) {
  .section__glow {
    height: 100vw;
    width: 100vw;
  }
}
.section__image, .section__text {
  position: relative;
  z-index: 10;
}
.section__body {
  color: var(--color-2);
  font-family: var(--font-2);
  font-size: var(--font-size-l);
  hyphens: auto;
  line-height: var(--line-height-l);
}
@media (min-width: 768px) {
  .section__body {
    font-size: var(--font-size-xl);
  }
}
.section__body a {
  color: #FFF;
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.5);
  text-decoration-thickness: 1px;
  text-underline-offset: 0.15em;
  transition: 250ms ease;
}
.section__body a:hover {
  text-decoration-color: rgb(255, 255, 255);
}
.section__body p {
  margin: 1em 0;
}
.section__body p:last-child {
  margin-bottom: 0;
}

.teamMember {
  line-height: var(--line-height-l);
  text-align: center;
  padding: 0 1rem 1rem 1rem;
}
.teamMember__photo {
  aspect-ratio: 1/1;
  border-radius: 50%;
  margin: 0 auto 1rem auto;
  width: 112px;
}
.teamMember__name {
  margin-bottom: 0.25em;
}
.teamMember__role {
  font-size: var(--font-size-s);
}
.teamMember__location {
  color: var(--color-2);
  font-size: var(--font-size-xs);
}
.teamMember--small .teamMember__photo {
  width: 64px;
}
.teamMember--small .teamMember__name {
  font-size: var(--font-size-s);
}

@keyframes testimonial {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.testimonial {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  opacity: 0;
  text-align: center;
  transform: translate3d(0, 1rem, 0);
}
.testimonial__photo {
  aspect-ratio: 1/1;
  border-radius: 50%;
  height: 80px;
  margin: 0 auto 1.5rem;
}
.testimonial__quote {
  font-family: var(--font-2);
  font-size: var(--font-size-l);
  margin-bottom: 1.5rem;
  line-height: var(--line-height-l);
}
.testimonial__quote i {
  font-style: italic;
}
.is-visibleOnce .testimonial {
  animation: testimonial 2s ease both;
}
.is-visibleOnce .testimonial--1 {
  animation-delay: 250ms !important;
}
.is-visibleOnce .testimonial--2 {
  animation-delay: 500ms;
}
.is-visibleOnce .testimonial--3 {
  animation-delay: 750ms;
}
.is-visibleOnce .testimonial--4 {
  animation-delay: 1000ms;
}

@keyframes textSplitChar {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.textSplit {
  --duration: 1s;
}
.textSplit .word {
  --word-index: 0;
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transform: translate3d(0, 1em, 0);
  vertical-align: bottom;
  white-space: nowrap;
}
.textSplit .word::after {
  content: " ";
  white-space: pre;
}
.textSplit .space {
  vertical-align: bottom;
}
.textSplit .char {
  display: inline-block;
  opacity: 0;
  transform: translate3d(0, 1em, 0);
}
.textSplit.is-textSplitVisible .word {
  animation: textSplitChar var(--duration) cubic-bezier(0.5, 0, 0.25, 1) both;
  animation-delay: calc(250ms * var(--word-index) / var(--num-words));
}

.wrapper {
  margin: 0 auto;
  max-width: calc(1280px + var(--gutter) * 2);
  padding: 0 var(--gutter);
}

.u-text-serif {
  color: var(--color-2);
  font-family: var(--font-2);
  font-size: var(--font-size-xl);
  line-height: var(--line-height-l);
}
.u-text-nbsp {
  white-space: nowrap;
}

.u-hide {
  display: none !important;
}

.u-hideEmpty:empty {
  display: none !important;
}

@media (max-width: 319px) {
  .xs\:u-show {
    display: none !important;
  }
}
@media (min-width: 320px) {
  .xs\:u-hide {
    display: none !important;
  }
}
@media (max-width: 374px) {
  .s\:u-show,
  .m\:u-show,
  .l\:u-show,
  .xl\:u-show {
    display: none !important;
  }
}
@media (min-width: 375px) {
  .s\:u-hide {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .m\:u-show,
  .l\:u-show,
  .xl\:u-show {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .m\:u-hide {
    display: none !important;
  }
}
@media (max-width: 1023px) {
  .l\:u-show,
  .xl\:u-show {
    display: none !important;
  }
}
@media (min-width: 1024px) {
  .l\:u-hide {
    display: none !important;
  }
}
@media (max-width: 1439px) {
  .xl\:u-show {
    display: none !important;
  }
}
@media (min-width: 1440px) {
  .xl\:u-hide {
    display: none !important;
  }
}