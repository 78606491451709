.team {
    &__block {
        background-color: var(--color-3);
        border-radius: 8px;
        padding: 1.5rem 0;

        @include screen-min(m) {
            padding: 3rem 1rem;
        }

        &:not(:last-child) {
            margin-bottom: .5rem;

            @include screen-min(m) {
                margin-bottom: 1rem;
            }
        }

        h6 {
            margin-bottom: 2.5rem;
        }

        &--lighter {
            background-color: rgb(var(--color-3-rgb) / 50%);
        }
    }
}