.architecture {
    .grid {
        @include screen-min(l) {
            flex-direction: row-reverse;
        }
    }

    &__arrow {
        position: absolute;
        height: 100%;
        top: 0;
        width: auto;
        left: 0;
    }

    &__tiers {
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;

        @include screen-min(l) {
            flex-direction: column;
        }
    }

    &__stack {
        margin: auto;
        max-height: 40vh;
        position: relative;

        @include screen-min(l) {
            max-height: min(65vh, 600px);
        }
    }
}