.btn {
    align-items: center;
    backdrop-filter: blur(4px);
    background: transparent;
    border-radius: 20px;
    box-shadow: inset 0 0 12px 2px rgb(var(--color-2-rgb) / 50%);
    color: #FFF;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    gap: 0.5em;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    text-decoration: none;
    transition: box-shadow 250ms ease;

    @include screen-min(m) {
        border-radius: 26px;
        gap: 0.75em;
        height: 52px;
        line-height: 52px;
        padding: 0 26px;
    }

    span {
        display: inline-block;
        position: relative;
    }

    &__icon {
        height: 16px;
        position: relative;
        width: 16px;
    }

    &:active {
        span,
        .btn__icon {
            top: 1px;
        }
    }

    &:hover {
        box-shadow: inset 0 0 8px 2px rgb(var(--color-2-rgb) / 75%);
    }
}