.nav {
    background-image: linear-gradient(
        to bottom,
        rgb(var(--color-1-rgb) / 100%),
        rgb(var(--color-1-rgb) / 0%) 100%
    );
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    .wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 1.25rem var(--gutter);

        @include screen-min(m) {
            padding: 1.5rem var(--gutter);
        }
    }

    &__logo {
        height: 36px;
        width: auto;

        @include screen-min(m) {
            height: 56px;
        }
    }

    &__ctas {
        display: flex;
        gap: .5rem;
    }
}