.teamMember {
    line-height: var(--line-height-l);
    text-align: center;
    padding: 0 1rem 1rem 1rem;

    &__photo {
        aspect-ratio: 1/1;
        border-radius: 50%;
        margin: 0 auto 1rem auto;
        width: 112px;
    }

    &__name {
        margin-bottom: 0.25em;
    }

    &__role {
        font-size: var(--font-size-s);
    }

    &__location {
        color: var(--color-2);
        font-size: var(--font-size-xs);
    }

    &--small {
        .teamMember__photo {
            width: 64px;
        }

        .teamMember__name {
            font-size: var(--font-size-s);
        }
    }
}